import type { SearchProvince } from "./types";
import type { SearchParams } from "../types";

import request from "utils/request";
import { formatFilters } from "helpers";

export const getProvinces = ({
  page = 1,
  pageSize = 10,
  equalFilters = {},
  sort,
}: SearchParams): Promise<SearchProvince> => {
  const options = {
    url: "/core/api/v1/provinces/search",
    params: {
      ...{ sort },
      ...formatFilters(equalFilters, "eq"),
      size: pageSize,
      page,
    },
  };

  return request.get(options.url, { params: options.params });
};
