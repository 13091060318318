import type { ErrorResponse, SearchParams } from "apis/types";
import type { SearchRegion, RegionResponse } from "apis/regions/types";

import { useQuery, UseQueryResult } from "react-query";

import { getRegions, getRegion } from "apis";

export const useRegions = (
  meta: SearchParams
): UseQueryResult<SearchRegion, ErrorResponse> => {
  const { enabled = true, ...params } = meta;

  return useQuery(["regions", params], () => getRegions(meta), {
    initialData: {
      items: [],
      page: 1,
      size: 10,
      pages: 0,
      total: 0,
      hasNext: false,
      hasPrevious: false,
    },
    keepPreviousData: true,
    enabled,
  });
};

export const useRegion = (
  id: number
): UseQueryResult<RegionResponse, ErrorResponse> =>
  useQuery(["region", id], () => getRegion(id), {
    enabled: !!id,
  });
