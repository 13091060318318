import type { ErrorResponse, SearchParams } from "apis/types";
import type { SearchProvince } from "apis/provinces/types";

import { useQuery, UseQueryResult } from "react-query";

import { getProvinces } from "apis";

export const useProvinces = (
  meta: SearchParams
): UseQueryResult<SearchProvince, ErrorResponse> => {
  const { enabled = true, ...params } = meta;

  return useQuery(["provinces", params], () => getProvinces(meta), {
    initialData: {
      items: [],
      page: 1,
      size: 10,
      pages: 0,
      total: 0,
      hasNext: false,
      hasPrevious: false,
    },
    keepPreviousData: true,
    enabled,
  });
};
