import type { RegionResponse, SearchRegion } from "./types";
import type { SearchParams } from "../types";

import request from "utils/request";
import { formatFilters } from "helpers";

export const getRegions = ({
  page = 1,
  pageSize = 10,
  equalFilters = {},
  sort,
}: SearchParams): Promise<SearchRegion> => {
  const options = {
    url: "/core/api/v1/regions/search",
    params: {
      ...{ sort },
      ...formatFilters(equalFilters, "eq"),
      size: pageSize,
      page,
    },
  };

  return request.get(options.url, { params: options.params });
};

export const getRegion = (regionId): Promise<RegionResponse> =>
  request.get(`/core/api/v1/regions/${regionId}`);
