import type { ErrorResponse, SearchParams } from "apis/types";
import type { SearchCity } from "apis/cities/types";

import { useQuery, UseQueryResult } from "react-query";

import { getCities } from "apis";

export const useCities = (
  meta: SearchParams
): UseQueryResult<SearchCity, ErrorResponse> => {
  const { enabled = true, ...params } = meta;

  return useQuery(["cities", params], () => getCities(meta), {
    initialData: {
      items: [],
      page: 1,
      size: 10,
      pages: 0,
      total: 0,
      hasNext: false,
      hasPrevious: false,
    },
    keepPreviousData: true,
    enabled,
  });
};
