import type { Geocode } from "apis/google/types";
import type { ErrorResponse } from "apis/types";
import type { AxiosResponse } from "axios";

import { useQuery, UseQueryResult } from "react-query";

import { getGeocode } from "apis";

export const useGeocode = (
  latlng?: string
): UseQueryResult<AxiosResponse<Geocode>, ErrorResponse> =>
  useQuery(["geocode"], () => getGeocode(latlng as string), {
    enabled: !!latlng,
  });
